import React, {useEffect, useState} from 'react';
import {appWords} from "../../../config/Constants";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import ImageComponent from "../../AppComponents/ImageComponent";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import CouponPurchaseModalComponent from "../../AppComponents/CouponPurchaseModalComponent";
import {hasAValue} from "../../../uitls/SharedFunctions";
import {useDispatch, useSelector} from "react-redux";
import {availableCouponCall, getProfileCall, purchaseCouponCall} from "../../../api/ApiCalls";
import {failedAlertFunction} from "../../../uitls/AlertFunctions";
import {updateShowAuthModalRS} from "../../../redux/reduxActions/reduxStoreActions";
import {useHandleLinkClick} from '../../../hooks/useHandleLinkClick';


export default function CouponDetailAndPurchaseSection({webshopAction, completedPurchase, showUserCoupons}) {
    let initStrings = {
        order_now: stringsTranslate(appWords.order_now),
        total_amount: stringsTranslate(appWords.total_amount),
        transaction_costs: stringsTranslate(appWords.transaction_costs),
        how_many_discount_codes_do_you_want_to_order: stringsTranslate(appWords.how_many_discount_codes_do_you_want_to_order),
        you_will_be_redirected_to_the_website1: stringsTranslate(appWords.you_will_be_redirected_to_the_website1),
        you_will_be_redirected_to_the_website2: stringsTranslate(appWords.you_will_be_redirected_to_the_website2),
        you_can_easily_purchase_a_ticket: stringsTranslate(appWords.you_can_easily_purchase_a_ticket),
        you_reached_the_maximum_of_allowed_vouchers_for_this_action: stringsTranslate(appWords.you_reached_the_maximum_of_allowed_vouchers_for_this_action),
        the_max_purchasable_vouchers_for_this_action_is: stringsTranslate(appWords.the_max_purchasable_vouchers_for_this_action_is),
        the_min_purchasable_vouchers_for_this_action_is: stringsTranslate(appWords.the_min_purchasable_vouchers_for_this_action_is),
        the_requested_number_of_coupons_is_not_available_for_this_action_max_is: stringsTranslate(appWords.the_requested_number_of_coupons_is_not_available_for_this_action_max_is),
        number_of_vouchers_should_not_be_empty: stringsTranslate(appWords.number_of_vouchers_should_not_be_empty),
        server_error: stringsTranslate(appWords.server_error),
        students_discounts:stringsTranslate(appWords.students_discounts),
        login_cl: stringsTranslate(appWords.login_cl),
        vouchers_left: stringsTranslate(appWords.vouchers_left),
        conditions: stringsTranslate(appWords.conditions),
        about_offer: stringsTranslate(appWords.about_offer),
        youCanOrder: stringsTranslate(appWords.youCanOrder),
        voucherBuy: stringsTranslate(appWords.voucherBuy),
        pricePerVoucher: stringsTranslate(appWords.pricePerVoucher),
    }

    const appLanguageRS = useSelector(state => state.appLanguageRS);
    const [couponNumber, setCouponNumber] = useState(1)
    const [showCouponPurchaseModal, setShowCouponPurchaseModal] = useState(false);
    const [intiVariables, setIntiVariables] = useState({})
    const [amount, setAmount] = useState('');
    const [availableVoucher, setAvailableVoucher] = useState(0);
    const [startDate, setStartDate] = useState();
    const [condition, setCondition] = useState('');
    const [profileData, setProfileData] = useState(null);
    const authDataRS = useSelector(state => state.authDataNEWRS);
    const dispatch = useDispatch()
    let contentLanguage = appLanguageRS === "en" ? "en" : "nl"
    const handleLinkClick = useHandleLinkClick();


    useEffect(() => {
        getAvailableVoucher()
        getUserProfile()
        let intiVariablesLocal = {}

        if (hasAValue(webshopAction)) {

            let language = appLanguageRS === "en" ? "" : "_" + appLanguageRS
            intiVariablesLocal['image'] = webshopAction.company?.backgroundImage
            intiVariablesLocal['icon'] = webshopAction.company.logo
            intiVariablesLocal['title'] = webshopAction.company.name
            intiVariablesLocal['promotion'] = webshopAction.asset?.fileName
            intiVariablesLocal['transactionFee'] = webshopAction.transactionFee
            intiVariablesLocal['maxPurchasableVouchers'] = webshopAction?.maxPurchasableVouchers
            intiVariablesLocal['minPurchasableVouchers'] = webshopAction?.minPurchasableVouchers
            intiVariablesLocal['price'] = webshopAction?.price ? parseFloat(webshopAction?.price).toFixed(2) : parseFloat(0).toFixed(2)
            intiVariablesLocal['subtitle'] = webshopAction.localization[contentLanguage].subtitle
            intiVariablesLocal['description'] = webshopAction.localization[contentLanguage].description
            intiVariablesLocal['termsNConditions'] = webshopAction.localization[contentLanguage].termsNConditions
            setStartDate(webshopAction.localization.startDate)
            // intiVariablesLocal['description'] = webshopAction[appLanguageRS].fullDes
            // intiVariablesLocal['commission_groups'] = deals

        }
        setIntiVariables(intiVariablesLocal)

    }, [webshopAction, appLanguageRS])

    return (
        <div className="p-0 m-0">
            <div className="container">
                <div className="row ">
                    <div className="col-lg-6 col-md-12 col-sm-12 mb-md-3">
                        <ImageComponent
                            src={intiVariables.image} alt={`coupon-`}/>
                        <p className="font-weight-bold mt-5 d-none d-lg-block">{initStrings.conditions}</p>
                        <div className='col-12 mb-5 ul-inherit d-none d-lg-block voucher-terms-conditions'
                             dangerouslySetInnerHTML={{__html: intiVariables.termsNConditions}}/>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-12 d-none d-md-block">
                                <ImageComponent src={intiVariables.icon}
                                                className="col-lg-3 col-md-12 bg-white radius-15 store-discount-details-card-logo shadow"
                                                alt="" height={98} width={98}
                                />
                            </div>

                            <div
                                className="col-lg-2 col-md-2 col-sm-12 d-flex justify-content-center d-block d-md-none logo-discount-mobile">
                                <ImageComponent src={intiVariables.icon}
                                                className="col-lg-3 col-md-12 bg-white radius-15 store-discount-details-card-logo shadow"
                                                alt="" height={98} width={98}
                                />
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-12 title-position mt-md-2 mt-sm-2">
                                <h4 className="mt-5 mt-lg-0 mt-md-0 mt-xl-0 text-knaek-title ml-lg-4">{initStrings.students_discounts} {intiVariables.title}</h4>
                                <div className="mt-2 divider-horizontal ml-lg-4"/>
                                <p className="mt-3 text-knaek-primary font-weight-bold ml-lg-4">{intiVariables.subtitle}</p>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-12 title-position mt-3">
                                <p className="font-weight-bold">{initStrings.about_offer}</p>
                                <p className=" mb-2 discount-description">{intiVariables.description}</p>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-12 title-position mt-3">
                                <p className="font-weight-bold d-block d-md-none">{initStrings.conditions}</p>
                                <div className='col-12  mb-2 d-block d-md-none ul-inherit'
                                     dangerouslySetInnerHTML={{__html: intiVariables.termsNConditions}}/>
                            </div>


                        </div>

                        {!completedPurchase &&
                            <div className="row">
                                <div className='col-12 mt-3 align-content-center'>
                                    <div className='row'>
                                        <p className="col-12 font-weight-bold">{initStrings.how_many_discount_codes_do_you_want_to_order}</p>
                                        <p className="col-12 col-md-6 mt-1">
                                            {initStrings.youCanOrder + " "}
                                            <span className="mt-1 text-knaek-primary">
                                             {availableVoucher}
                                        </span>
                                            {" " + initStrings.voucherBuy}
                                        </p>
                                    </div>
                                </div>

                            <div className='m-1 col-12'>
                                <input
                                    type="number"
                                    className="px-2 d-flex col-12 mt-3 border-input pl-3"
                                    style={{height: "50px"}}
                                    name="couponNumber"
                                    placeholder='1'
                                    value={couponNumber}
                                    min={intiVariables.minPurchasableVouchers}
                                    max={intiVariables.maxPurchasableVouchers}
                                    onChange={(e) => setCouponNumber(e.target.value)}
                                />
                            </div>

                                <div className='mt-3 coupon-price-div-style mb-2'>
                                    <p className="mt-1 font-size-14">
                                        {initStrings.pricePerVoucher + ' (' + intiVariables?.price + ' euro)'}
                                    </p>
                                    <p className="mt-1 font-size-14">
                                        {parseFloat((intiVariables?.price)) > 0 && initStrings.transactionCosts + ' (' + intiVariables?.transactionFee + ' euro)'}
                                    </p>


                                    <p className="mt-3 text-knaek-primary font-weight-bold">
                                        {initStrings.total_amount} <span className="mt-1 text-knaek-primary">
                                        ({parseFloat((intiVariables?.price * couponNumber).toFixed(2)) + parseFloat(intiVariables?.transactionFee)} euro)
                                    </span>
                                    </p>
                                </div>
                                {authDataRS ?
                                    <div className={"col-12 d-flex justify-content-center "}>
                                    <KnaekPrimaryButton
                                        title={initStrings.order_now}
                                        className={"mt-2 col-12 "}
                                        onClick={() => purchaseCoupon()}
                                    />
                                </div>
                                :
                                <div className={"col-12 d-flex justify-content-center "}>
                                    <KnaekPrimaryButton
                                        title={initStrings.login_cl}
                                        className={" mt-2 col-12"}
                                        onClick={() =>dispatch(updateShowAuthModalRS(true))}
                                    />
                                </div>
                            }
                            <div className='mt-5 mb-5 coupon-price-div-style'>
                                <p className="m-2">
                                    {initStrings.you_will_be_redirected_to_the_website1}
                                    {" (" + profileData?.email + ") "}
                                    {initStrings.you_will_be_redirected_to_the_website2}
                                </p>
                            </div>
                        </div>
                    }

                    </div>
                </div>
            </div>


            {showCouponPurchaseModal &&
                <CouponPurchaseModalComponent
                    amount={amount}
                    // startDate={startDate}
                    onClose={() => setShowCouponPurchaseModal(false)}/>}
        </div>
    );

    async function purchaseCoupon() {
        try {
            let response = null

            response = await purchaseCouponCall(webshopAction.id, parseInt(couponNumber))
            if (response)
            {
                if(response.data.status == 'PURCHASE')
                    window.location.href = response?.data?.paymentRedirectUrl
                else
                    showUserCoupons(response.data.vouchers)

            }

        } catch (e) {
            if (!e.response.data.message)
                failedAlertFunction(initStrings.server_error, '')
            else if (e.response.data.message == "numberOfVouchers should not be empty")
                failedAlertFunction(initStrings.number_of_vouchers_should_not_be_empty, '')
            else if (e.response.data.message == "You reached the maximum of allowed Vouchers for this action")
                failedAlertFunction(initStrings.you_reached_the_maximum_of_allowed_vouchers_for_this_action, '')
            else if (e.response.data.message.includes("The Max Purchasable Vouchers for this action is"))
                failedAlertFunction(initStrings.the_max_purchasable_vouchers_for_this_action_is  + e.response.data.message.split(" ")[e.response.data.message.split(" ").length - 1], '')
            else if (e.response.data.message.includes("The Min Purchasable Vouchers for this action is"))
                failedAlertFunction(initStrings.the_min_purchasable_vouchers_for_this_action_is  + intiVariables.minPurchasableVouchers, '')
            else if (e.response.data.message.includes("The Requested Number of coupons is not available for this action, Max is"))
                failedAlertFunction(initStrings.the_requested_number_of_coupons_is_not_available_for_this_action_max_is  + e.response.data.message.split(" ")[e.response.data.message.split(" ").length - 1], '')
        }
    }

    async function getAvailableVoucher() {
        try {
            let response = await availableCouponCall(webshopAction.id)
            setAvailableVoucher(response?.data)
            setCouponNumber(response?.data)
        } catch (e) {

        }
    }

    async function getUserProfile() {
        try {
            let response = await getProfileCall()
            setProfileData(response)
        } catch (e) {

        }
    }
}
