import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { stringsTranslate } from "../../locales/CustomTranslater";
import { appWords } from "../../config/Constants";
import { useCookies } from 'react-cookie';

export default function SMPixelNotificationComponent({timer,setTimer}) {
  const [cookies, setCookie] = useCookies();
  const [showSMModal, setShowSMModal] = useState( false );

  if(!cookies?.SMNotice)
      setTimeout(() => {
        setShowSMModal(true)
        setCookie('SMNotice', true);
      }, 2000)


  const initString = {
    headerLoginLink: stringsTranslate(appWords.login_link_component_title),
    bodyLoginLink: stringsTranslate(appWords.body_login_link),
    sm_pixel_notification: stringsTranslate(appWords.sm_pixel_notification),
    sm_pixel_notification_title: stringsTranslate(appWords.sm_pixel_notification_title),
  };
  
  const hideSMModal = () => {
    setCookie('SMNotice', true);
    setShowSMModal(false)
  }
  return (
    <Modal
      className="modal fade radius-15 mt-4"
      size={"lg"}
      show={showSMModal}
      onHide={() => {hideSMModal()}}
    >
      <div className="radius-15 bg-light" role="document">
        <div className="radius-15">
          <div className={"radius-15 pt-3 bg-light"}>
            <div
              className="card-header bg-light border-0 d-flex justify-content-end cursor-pointer pb-0"
              onClick={() => {hideSMModal()}}
            >
              <svg
                className=" mt-3 mr-3"
                width="15"
                height="15"
                viewBox="0 0 32 32"
              >
                <path
                  fill="black"
                  d="M31.29,27.48a2.7,2.7,0,0,1-3.81,3.81L16,19.83,4.52,31.29A2.7,2.7,0,0,1,.71,27.48L12.17,16,.71,4.52A2.7,2.7,0,0,1,4.52.71L16,12.17,27.48.71a2.7,2.7,0,0,1,3.81,3.81L19.83,16Z"
                />
              </svg>
            </div>
            <div className="modal-body  radius-15 pt-0">
              <div className="card border-top border-0 pt-0">
                <div className="card-header bg-light border-0 d-flex pt-0">
                  <h4
                    className="card-title title-with-border-bottom mb-0 pb-3"
                  >
                    {initString.sm_pixel_notification_title}
                  </h4>
                </div>
                <div className="card-header bg-light border-0 d-flex justify-content-center align-items-center">
                  <img src="../../assets/images/social-media.png" style={{maxWidth: '100%', width: '200px', paddingRight: '20px'}} />
                  <p
                    className="card-title mb-0"
                  >
                    {initString.sm_pixel_notification}
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
